import { Injectable } from '@angular/core';
import { NewsTitleCache, NewsTitleData, Pack, assistDB } from '@assist/shared/data';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { toDataSourceRequestString } from '@progress/kendo-data-query';
import { ApiService, privateNews, publicNews, titleNews } from '@cue/api';
import { from, mapTo, Observable, of } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { News } from './store';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  constructor(private apiService: ApiService) {}

  getNews(skip: number, take: number, from?: Date, to?: Date): Observable<{ total: number; data: News[] }> {
    let query = toDataSourceRequestString({
      take: take,
      skip: skip,
      sort: [
        {
          field: 'priority',
          dir: 'desc',
        },
      ],
    });

    if (from) {
      const value = from.toISOString();
      query = query + '&fromString=' + encodeURIComponent(value);
    }

    if (to) {
      const value = to.toISOString();
      query = query + '&toString=' + encodeURIComponent(value);
    }
    return this.apiService.call(publicNews(query)).pipe(
      map((resp) => ({
        data: resp.data.filter((x) => x.listed),
        total: resp.total,
      })),
    );
  }

  getNewsForTitle(): Observable<Pack<NewsTitleData>> {
    let timestamp = null;
    const request = (timestamp) =>
      this.apiService.callObserveResponse(titleNews(timestamp)).pipe(
        switchMap((response: HttpResponse<any>) => {
          if (response.status === 204) {
            return from(assistDB.news.get(1)).pipe(map((x) => x.data));
          }

          if (response.status === 200) {
            let cacheHeader = undefined;
            try {
              cacheHeader = response.headers.get('CachedNewsTitleTimeStamp');
            } catch {
              cacheHeader = null;
            }
            if (cacheHeader) {
              return from(
                assistDB.news.put(
                  {
                    id: 1,
                    timestamp: cacheHeader,
                    data: response.body,
                  } as NewsTitleCache,
                  1,
                ),
              ).pipe(mapTo(response.body as any));
            } else {
              return from(assistDB.news.clear()).pipe(mapTo(response.body as any[]));
            }
          }
          console.error('Error loading news title - check network tab');
          return of(null);
        }),
      );

    return from(assistDB.news.get(1))
      .pipe(
        switchMap((cache) => {
          if (cache != null) {
            // je nacachovano
            timestamp = encodeURIComponent(cache.timestamp);
          } else {
            // neni nacachovano - nedela se nic
          }
          return request(timestamp);
        }),
      )
      .pipe(
        map((x) => ({
          loading: false,
          data: x,
          error: null,
        })),
        catchError((e) =>
          of({
            loading: false,
            data: null,
            error: e,
          }),
        ),
        startWith({
          loading: true,
          data: null,
          error: null,
        }),
      );
  }

  getNewsById(id: string) {
    const filters = {
      take: 1,
      skip: 0,
      filter: {
        filters: [
          {
            field: 'id',
            value: id,
            operator: 'eq',
          },
        ],
        logic: 'and',
      },
    } as any;

    const query = toDataSourceRequestString(filters);
    return this.apiService.call(privateNews(query)).pipe(map((resp) => resp.data[0]));
  }
}
